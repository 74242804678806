<template>
  <div class="footer">
    <div class="footerLinkWrap">
      <div class="footerLinkGroup">
        <div class="footerLinkHeader">{{$t('web.FluenDay_learning')}}</div>
        <router-link class="footerLink" to="/clips">{{$t('main.learn')}}</router-link>
        <router-link class="footerLink" to="/courses">{{$t('main.course')}}</router-link>
        <a class="footerLink" href="https://support.fluenday.com/en/support/home" target="_blank">{{$t('web.help_center')}}</a>
      </div>
      <div class="footerLinkGroup">
        <div class="footerLinkHeader">{{$t('web.social_media')}}</div>

        <template v-if="userLanguage === 'jp'">
          <a class="footerLink" href="https://www.youtube.com/@fluenday_jp" target="_blank">{{$t('web.YouTube')}}</a>
          <a class="footerLink" href="https://twitter.com/FluenDay_Japan" target="_blank">{{$t('web.Twitter')}}</a>
          <a class="footerLink" href="https://www.instagram.com/fluenday.japan" target="_blank">{{$t('web.Instagram')}}</a>
          <a class="footerLink" href="https://www.tiktok.com/@fluenday.japan" target="_blank">{{$t('web.TikTok')}}</a>
          <a class="footerLink" href="https://www.facebook.com/FluenDay.Japan" target="_blank">{{$t('web.Facebook')}}</a>
        </template>
        
        <template v-else>
          <a class="footerLink" href="https://www.youtube.com/@fluenday" target="_blank">{{$t('web.YouTube')}}</a>
          <a class="footerLink" href="https://www.instagram.com/fluenday" target="_blank">{{$t('web.Instagram')}}</a>
          <a class="footerLink" href="https://www.facebook.com/FluenDay" target="_blank">{{$t('web.Facebook')}}</a>
        </template>
      </div>
      <div class="footerLinkGroup">
        <div class="footerLinkHeader">{{$t('web.terms&privacy')}}</div>
        <a class="footerLink" href="https://www.fluenday.com/terms-conditions.html" target="_blank">{{$t('main.terms_of_use')}}</a>
        <a class="footerLink" href="https://www.fluenday.com/privacy-policy.html" target="_blank">{{$t('main.privacy_policy')}}</a>
      </div>
      <div class="footerLinkGroup">
        <div class="footerLinkHeader">{{$t('web.about_fluenday')}}</div>
        <a class="footerLink" href="https://apps.apple.com/us/app/id1603548515" target="_blank">{{$t('web.fluenday_for_ios')}}</a>
        <a class="footerLink" href="https://play.google.com/store/apps/details?id=com.enpal" target="_blank">{{$t('web.fluenday_for_android')}}</a>
        
        <template v-if="userLanguage === 'jp'">
          <router-link class="footerLink" to="/links">{{$t('web.links')}}</router-link>
        </template>
      </div>
    </div>
    <div class="divider"></div>
    <div class="footerWrap">
      <div class="footerItem">©FLUENDAY INC. 2024 / ©VILIKE HK CO., LIMITED 2024</div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'

  export default {
    computed: {
      ...Vuex.mapGetters(['userLanguage'])
    }
  }
</script>

<style lang="scss" scoped>
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0A189D;
    padding: 50px 0;

    .divider {
      margin: 50px auto;
      width: 1200px;
      height: 1px;
      background: #ffffff4f;
    }

    .footerWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1200px;

      .slogan {
        font-size: 22px;
        line-height: unset;
        letter-spacing: unset;
        margin-right: 40px;
      }

      .startLink {
        height: 40px;
        line-height: 40px;
        min-width: 120px;
        padding: 0 16px;
        font-size: 18px;
      }

      .footerItem {
        margin-right: 40px;
        font-family: -apple-system, BlinkMacSystemFont, "Microsoft YaHei", sans-serif;
        font-size: 12px;
        color: #FFFFFF;

        a {
          text-decoration: none;
          color: #FFFFFF;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footerLinkWrap {
      width: 1200px;
      display: flex;
      justify-content: space-around;
      .footerLinkGroup {
        display: flex;
        flex-direction: column;
        .footerLinkHeader {
          font-family: Helvetica;
          font-weight: 700;
          font-size: 20px;
          color: #FFFFFF;
        }
        .footerLink {
          margin-top: 20px;
          font-family: Helvetica;
          font-size: 16px;
          color: #FFFFFF;
          opacity: 0.8;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .footer {
      padding: 2rem;
      .divider {
        width: 100%;
        margin: 2rem auto;
      }
      .footerWrap {
        width: 100%;
        flex-direction: column;
        .slogan {
          margin-right: 0;
          margin-bottom: 2rem;
          white-space: pre-line;
        }
        .footerItem {
          margin-right: 0;
        }
      }
      .footerLinkWrap {
        width: 100%;
        margin-top: 0;
        flex-direction: column;
        .footerLinkGroup {
          margin-top: 2rem;
        }
      }
    }
  }
</style>